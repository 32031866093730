import {
  Raycaster,
  Clock,
  Scene,
  EquirectangularReflectionMapping,
  SRGBColorSpace,
  ACESFilmicToneMapping,
  PerspectiveCamera,
  AnimationMixer,
  AnimationClip,
  Vector2,
  PlaneGeometry,
  Mesh,
  LoopOnce,
  MathUtils,
  WebGLRenderer,
  ShadowMaterial,
  MeshStandardMaterial,
  TextureLoader,
  RepeatWrapping,
  MeshBasicMaterial,
  Object3D,
  SpotLight,
  Group,
  PositionalAudio,
  AudioListener,
  AudioLoader,
  Audio,
  LoadingManager,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { MeshoptDecoder } from 'three/addons/libs/meshopt_decoder.module.js';
// import Stats from 'three/examples/jsm/libs/stats.module';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import MouseFollower from 'mouse-follower';
import gsap from 'gsap';
import { initializeApp } from 'firebase/app';
import { UAParser } from 'ua-parser-js';

const firebaseConfig = {
  apiKey: 'AIzaSyAhnPK78zwc0Nr03VLo6tnYMCAl9Qw744M',
  authDomain: 'frank-pascal.firebaseapp.com',
  projectId: 'frank-pascal',
  storageBucket: 'frank-pascal.appspot.com',
  messagingSenderId: '357033532344',
  appId: '1:357033532344:web:891b4f71c11d38c83b0d4d',
  measurementId: 'G-7L55Z9ZM5X',
};

initializeApp(firebaseConfig);

const { device } = UAParser(
  'Mozilla/5.0 (X11; U; Linux armv7l; en-GB; rv:1.9.2a1pre) Gecko/20090928 Firefox/3.5 Maemo Browser 1.4.1.22 RX-51 N900'
);

console.log(device === 'mobile' || device === 'tablet');

MouseFollower.registerGSAP(gsap);

// Set our main variables
let scene;
let renderer;
let texture;
let camera;
let model;
let model2;
let model3;
let model4;
let model5;
let neck;
let waist;
let possibleAnims;
let mixer;
let idle;
let spinning;
let textVar;
let currentlyAnimating = false;
let min;
let hour;
let sec;
let sound;
let sound2;
let sound3;
let sound4;
let watch;
let salute;
let looking;
let anim;
const cursor = new MouseFollower({
  className: 'mf-cursor',
  ease: 'expo.out',
});
cursor.addState('-exclusion');
const clock = new Clock();
const raycaster = new Raycaster();
const MgroupM = new Group();
const Mgroup = new Group();
const Mgroup2 = new Group();
const Mgroup3 = new Group();
const Mgroup4 = new Group();
const Mgroup5 = new Group();
const ghour = new Group();
const gmin = new Group();
const gsec = new Group();
const MODEL_PATH = 'frank2.glb';
const MODEL_PATH2 = 'watch.glb';
const MODEL_PATH3 = 'phone.glb';
const MODEL_PATH4 = 'laptop.glb';
const MODEL_PATH5 = 'apple.glb';
const canvas = document.querySelector('#c');
const body = document.querySelector('body');
const name = document.querySelector('.name');
const containerload = document.getElementById('container-load');
const progressBar = document.getElementById('stop2');
const progresspercent = document.getElementById('progresspercent');
const startButton = document.getElementById('startButton');
const introtext = document.querySelector('.introtext');
const introimg = document.querySelector('.introimg');
const dialogwatch = document.querySelector('#dialogwatch');
const dialogAr = document.querySelector('#dialogAr');
const dialogmusic = document.querySelector('#dialogmusic');
const dialogapple = document.querySelector('#dialogapple');

const manager = new LoadingManager();

manager.onLoad = function () {
  startButton.style.opacity = 1;
  introtext.style.opacity = 1;
  introimg.style.opacity = 1;
  progresspercent.style.opacity = 0;
  containerload.style.pointerEvents = 'auto';
  name.style.strokeWidth = '0';
};

manager.onProgress = function (url, itemsLoaded, itemsTotal) {
  progressBar.attributes['offset'].value =
    (itemsLoaded / itemsTotal) * 100 + '%';
  progresspercent.innerHTML =
    'Portfolio Loading ' +
    Math.floor((itemsLoaded / itemsTotal) * 100) +
    '%';
};

manager.onError = function (url) {
  console.log('There was an error loading ' + url);
};
startButton.addEventListener('click', begin);
startButton.addEventListener('touchend', begin, { passive: false });
function begin() {
  window.addEventListener('wheel', onWheel, { passive: false });
  camera.position.z = 4.2;
  containerload.style.opacity = 0;
  containerload.style.pointerEvents = 'none';
  name.style.zIndex = '0';
  playModifierAnimation(idle, 0.25, spinning, 0.25);
  let tlp = gsap.timeline({});

  if (matchMedia('(pointer:coarse)').matches) {
    tlp.fromTo(
      Mgroup.position,
      { z: 4 },
      {
        z: 0,
        duration: 2,
        ease: 'power1',
        onStart: () => {
          sound4.play();
        },
      }
    );
  } else {
    tlp
      .fromTo(
        Mgroup.position,
        { z: 4 },
        {
          z: 0,
          duration: 2,
          ease: 'power1',
          onStart: () => {
            sound4.play();
          },
        }
      )
      .fromTo(
        Mgroup4.position,
        { x: 5 },
        {
          x: 1,
          duration: 1,
          ease: 'power1.inOut',
          onComplete: () => {
            sound.play();
            watch.play();
          },
        },
        '-=1'
      )
      .fromTo(
        Mgroup5.position,
        { x: -5 },
        {
          x: -1,
          duration: 1,
          ease: 'power1.inOut',
        },
        '-=1'
      )
      .fromTo(
        Mgroup2.position,
        { x: -5 },
        {
          x: -1.6,
          duration: 1,
          ease: 'power1.inOut',
          onStart: () => {
            sound2.play();
            sound2.offset = 0;
          },
        },
        '-=0.9'
      )
      .fromTo(
        Mgroup3.position,
        { x: 5 },
        {
          x: 1.6,
          duration: 1,
          ease: 'power1.inOut',
        },
        '-=1'
      )
      .fromTo(
        '.contact',
        { y: 50 },
        {
          y: 0,
          duration: 1,
          ease: 'power1.inOut',
          onComplete: () => {
            if (!sessionStorage.getItem('animationTriggered')) {
              // Trigger the animation
              setTimeout(() => {
                startAnimation();
              }, '500');
              // Store a flag in sessionStorage to indicate that the animation has been triggered
              sessionStorage.setItem('animationTriggered', true);
            }
          },
        },
        '-=1'
      );
  }
}

init();
function init() {
  // Init the scene
  scene = new Scene();

  new RGBELoader(manager)
    .setPath('')
    .load('neutral.hdr', function (texture) {
      texture.mapping = EquirectangularReflectionMapping;
      scene.environment = texture;
    });

  // Init the renderer
  renderer = new WebGLRenderer({
    canvas,
    antialias: true,
    alpha: true,
  });
  renderer.shadowMap.enabled = true;
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.outputColorSpace = SRGBColorSpace;
  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.powerPreference = 'high-performance';
  renderer.toneMappingExposure = 1;
  renderer.setClearColor(0x000000, 0);
  document.body.appendChild(renderer.domElement);

  // Add a camera
  camera = new PerspectiveCamera(
    35,
    window.innerWidth / window.innerHeight,
    0.1,
    10
  );
  camera.position.z = -4.2;

  const loader = new GLTFLoader(manager);
  loader.setMeshoptDecoder(MeshoptDecoder);
  loader.load(MODEL_PATH, function (gltf) {
    model = gltf.scene;
    const fileAnimations = gltf.animations;
    model.traverse((o) => {
      if (o.isMesh) {
        o.frustumCulled = false; // Fix the disapearing mesh due to Meshopt compression
        o.castShadow = true;
        o.receiveShadow = true;
        o.envMap = texture;
      }
      // Reference the neck and waist bones
      if (o.isBone && o.name === 'Neck') {
        neck = o;
      }
      if (o.isBone && o.name === 'Spine') {
        waist = o;
      }
    });

    model.scale.set(1, 1, 1);
    model.position.y = -1;
    Mgroup.position.z = 4;
    Mgroup.add(model);

    mixer = new AnimationMixer(model);
    const clips = fileAnimations.filter((val) => val.name !== 'idle');
    possibleAnims = clips.map((val) => {
      let clip = AnimationClip.findByName(clips, val.name);
      clip.tracks.splice(3, 3);
      clip.tracks.splice(9, 3);
      clip = mixer.clipAction(clip);
      return clip;
    });

    const idleAnim = AnimationClip.findByName(fileAnimations, 'idle');
    idleAnim.tracks.splice(3, 3);
    idleAnim.tracks.splice(9, 3);
    idle = mixer.clipAction(idleAnim);
    idle.play();

    const spinAnim = AnimationClip.findByName(fileAnimations, 'spin');
    spinAnim.tracks.splice(3, 3);
    spinAnim.tracks.splice(9, 3);
    spinAnim.duration /= 1.8;
    // spinAnim.setEffectiveTimeScale(0);
    spinning = mixer.clipAction(spinAnim);

    // loaderAnim.remove();
  });

  const loader2 = new GLTFLoader(manager);
  loader2.setMeshoptDecoder(MeshoptDecoder);
  loader2.load(MODEL_PATH2, function (gltf) {
    model2 = gltf.scene;
    model2.traverse((o) => {
      if (o.isMesh) {
        o.frustumCulled = false; // Fix the disapearing mesh due to Meshopt compression
        o.castShadow = true;
        o.receiveShadow = true;
        o.envMap = texture;
      }
    });
    model2.scale.set(4, 4, 4);

    if (matchMedia('(pointer:coarse)').matches) {
      Mgroup2.position.y = -2;
      Mgroup2.position.x = 0;
    } else {
      Mgroup2.position.y = 0;
      Mgroup2.position.x = -5;
    }
    model2.position.x = 0;

    min = model2.getObjectByName('min');
    hour = model2.getObjectByName('hour');
    ghour.scale.set(4, 4, 4);
    gmin.scale.set(4, 4, 4);
    gsec.scale.set(4, 4, 4);
    gmin.position.set(0, 0, 0);
    gsec.position.set(0, 0, 0);
    sec = model2.getObjectByName('sec');

    gmin.add(min);
    ghour.add(hour);
    gsec.add(sec);
    Mgroup2.add(gmin);
    Mgroup2.add(ghour);
    Mgroup2.add(gsec);
    model2.rotation.set(-0.3, 0, 0);
    gsec.rotation.set(-0.3, 0, 0);
    ghour.rotation.set(-0.3, 0, 0);
    gmin.rotation.set(-0.3, 0, 0);
    gsec.position.set(0, 0, 0.015);
    gmin.position.set(0, 0, 0.005);
    Mgroup2.add(model2);
    MgroupM.add(Mgroup2);
  });

  const loader3 = new GLTFLoader(manager);
  loader3.setMeshoptDecoder(MeshoptDecoder);
  loader3.load(MODEL_PATH3, function (gltf) {
    model3 = gltf.scene;
    model3.traverse((o) => {
      if (o.isMesh) {
        o.frustumCulled = false; // Fix the disapearing mesh due to Meshopt compression
        o.castShadow = true;
        o.receiveShadow = true;
        o.envMap = texture;
      }
    });

    model3.scale.set(0.2, 0.2, 0.2);
    Mgroup3.position.y = 0;
    Mgroup3.position.x = 5;
    model3.position.x = 0;
    Mgroup3.add(model3);
    MgroupM.add(Mgroup3);
    scene.add(MgroupM);
  });

  const loader4 = new GLTFLoader(manager);
  loader4.setMeshoptDecoder(MeshoptDecoder);
  loader4.load(MODEL_PATH4, function (gltf) {
    model4 = gltf.scene;
    model4.traverse((o) => {
      if (o.isMesh) {
        o.frustumCulled = false; // Fix the disapearing mesh due to Meshopt compression
        o.castShadow = true;
        o.receiveShadow = true;
        o.envMap = texture;
      }
    });

    model4.scale.set(0.04, 0.04, 0.04);
    Mgroup4.position.y = -0.4;
    model4.rotation.x = 0.2;
    Mgroup4.position.x = 5;
    Mgroup4.position.z = -1.2;
    model4.position.x = 0;
    Mgroup4.add(model4);
    MgroupM.add(Mgroup4);
  });

  const loader5 = new GLTFLoader(manager);
  loader5.setMeshoptDecoder(MeshoptDecoder);
  loader5.load(MODEL_PATH5, function (gltf) {
    model5 = gltf.scene;
    model5.traverse((o) => {
      if (o.isMesh) {
        o.frustumCulled = false; // Fix the disapearing mesh due to Meshopt compression
        o.castShadow = true;
        o.receiveShadow = true;
        o.envMap = texture;
      }
    });

    model5.scale.set(5, 5, 5);
    Mgroup5.position.x = -5;
    Mgroup5.position.z = -1.2;
    model5.position.x = 0;
    Mgroup5.add(model5);
    MgroupM.add(Mgroup5);
    scene.add(MgroupM);
  });

  const targetObject = new Object3D();
  targetObject.position.set(0, 0, 0);
  scene.add(targetObject);

  var lightsContainer = new Object3D();
  scene.add(lightsContainer);

  var spotlights = [];

  var spotlight1 = createSpotlight(0xa200ff, -2, 5, 0, Math.PI / 2);
  spotlights.push(spotlight1);

  var spotlight2 = createSpotlight(0x23b7fc, 2, 5, 0, Math.PI / 2);
  spotlights.push(spotlight2);

  var spotlight3 = createSpotlight(0x0000ff, 0, 3, 1, Math.PI / 3);
  spotlight3.castShadow = true;
  spotlight3.bias = -0.0005;
  spotlight3.shadow.mapSize.width = 1024;
  spotlight3.shadow.mapSize.height = 1024;

  spotlight3.shadow.camera.near = 0.1;
  spotlight3.shadow.camera.far = 50;
  spotlight3.shadow.camera.fov = 50;
  spotlights.push(spotlight3);

  function createSpotlight(color, x, y, z, angle) {
    var spotlight = new SpotLight(color, 2);
    spotlight.position.set(x, y, z);
    spotlight.angle = angle;
    spotlight.penumbra = 0.5;
    spotlight.decay = 10;

    spotlight.target = targetObject;
    lightsContainer.add(spotlight);
    return spotlight;
  }

  const rough = new TextureLoader(manager).load('roughness.jpg');
  rough.wrapS = RepeatWrapping;
  rough.wrapT = RepeatWrapping;
  rough.repeat.set(4, 4);
  const metal = new TextureLoader(manager).load('metal.jpg');
  metal.wrapS = RepeatWrapping;
  metal.wrapT = RepeatWrapping;
  metal.repeat.set(4, 4);
  const norm = new TextureLoader(manager).load('normal.jpg');
  norm.wrapS = RepeatWrapping;
  norm.wrapT = RepeatWrapping;
  norm.repeat.set(4, 4);
  const ao = new TextureLoader(manager).load('ao.jpg');
  ao.wrapS = RepeatWrapping;
  ao.wrapT = RepeatWrapping;
  ao.repeat.set(4, 4);
  const alpha = new TextureLoader(manager).load('alpha.jpg');

  // Shadow Catcher
  const shadowGeometry = new PlaneGeometry(18, 18, 1, 1);
  const shadowMaterial = new MeshStandardMaterial({
    color: 0x2e2e2e,
    roughnessMap: rough,
    metalnessMap: metal,
    aoMap: ao,
    alphaMap: alpha,
    normalMap: norm,
    transparent: true,
  });

  const shadowMaterial2 = new ShadowMaterial({
    opacity: 0.2,
  });

  // Add the Shadow Catcher to scene
  const shadowCatcher = new Mesh(shadowGeometry, shadowMaterial);
  shadowCatcher.rotation.x = -0.5 * Math.PI;
  shadowCatcher.position.y = -1;
  scene.add(shadowCatcher);

  const shadowCatcher2 = new Mesh(shadowGeometry, shadowMaterial2);
  shadowCatcher2.rotation.x = -0.5 * Math.PI;
  shadowCatcher2.receiveShadow = true;
  shadowCatcher2.position.y = -1;
  scene.add(shadowCatcher2);

  // Add the Clickable Mesh to scene
  const ClickGeometry = new PlaneGeometry(0.7, 1.95, 1, 1);
  const ClickMaterial = new MeshBasicMaterial({
    color: 0x000000,
    opacity: 0,
    transparent: true,
  });
  const clickMesh = new Mesh(ClickGeometry, ClickMaterial);
  clickMesh.position.z = 0.26;
  clickMesh.position.y = -0.2;
  clickMesh.name = 'clickmesh';
  Mgroup.add(clickMesh);
  scene.add(Mgroup);

  const listener = new AudioListener();
  camera.add(listener);

  // create a global audio source
  watch = new PositionalAudio(listener);
  sound = new Audio(listener);
  sound2 = new Audio(listener);
  sound3 = new Audio(listener);
  sound4 = new Audio(listener);
  salute = new Audio(listener);
  looking = new Audio(listener);

  // load a sound and set it as the Audio object's buffer
  const audioLoaderw = new AudioLoader(manager);
  audioLoaderw.load('./sounds/ticking.mp3', function (buffer) {
    watch.setBuffer(buffer);
    watch.setLoop(true);
    watch.setRefDistance(0.3);
    watch.setVolume(1);
  });
  Mgroup2.add(watch);
  const audioLoader = new AudioLoader(manager);
  audioLoader.load('./sounds/buzz.mp3', function (buffer) {
    sound.setBuffer(buffer);
    sound.setLoop(true);
    sound.setVolume(0.01);
  });
  const audioLoader2 = new AudioLoader(manager);
  audioLoader2.load('./sounds/sword.mp3', function (buffer) {
    sound2.setBuffer(buffer);
    sound2.setLoop(false);
    sound2.setVolume(1);
    sound2.offset = 0;
  });
  const audioLoader3 = new AudioLoader(manager);
  audioLoader3.load('./sounds/DrumLoop.mp3', function (buffer) {
    sound3.setBuffer(buffer);
    sound3.setLoop(false);
    sound3.setVolume(0.4);
  });
  const audioLoader4 = new AudioLoader(manager);
  audioLoader4.load('./sounds/woosh.mp3', function (buffer) {
    sound4.setBuffer(buffer);
    sound4.setLoop(false);
    sound4.setVolume(0.4);
  });
  const audioLoader6 = new AudioLoader(manager);
  audioLoader6.load('./sounds/salute.mp3', function (buffer) {
    salute.setBuffer(buffer);
    salute.setLoop(false);
    salute.setVolume(1);
  });
  const audioLoader7 = new AudioLoader(manager);
  audioLoader7.load('./sounds/looking.mp3', function (buffer) {
    looking.setBuffer(buffer);
    looking.setLoop(false);
    looking.setVolume(0.6);
  });
} // end of the init function

let planeMesh;
let font;
// Declare the updateClock function outside the FontLoader callback
function updateClock(planeMesh, font) {
  const time = new Date();
  const hours = time.getHours().toString().padStart(2, '0');
  const minutes = time.getMinutes().toString().padStart(2, '0');
  const clockText = hours + ':' + minutes;

  // Create a text geometry using the custom font
  const textGeometry = new TextGeometry(clockText, {
    font: font,
    size: 0.042,
    height: 0.05,
  });

  if (planeMesh.geometry) {
    planeMesh.geometry.dispose();
  }

  // Update the mesh with the new geometry
  planeMesh.geometry = textGeometry;
}

// Initialize planeMesh with a default value
planeMesh = new Mesh();

const fontLoader = new FontLoader(manager);
fontLoader.load('Auto.json', function (loadedFont) {
  font = loadedFont; // Assign the loaded font to the outer font variable

  // Create a material using the custom font
  const material = new MeshBasicMaterial({ color: 0xacaca4 });

  // Create a plane to display the digital clock
  const planeGeometry = new PlaneGeometry(2, 1);
  planeMesh = new Mesh(planeGeometry, material); // Assign the value to the outer planeMesh variable
  planeMesh.position.set(-0.068, 0.166, 0.267);
  planeMesh.rotation.set(-0.3, 0, 0);

  Mgroup2.add(planeMesh);

  // Call updateClock initially
  updateClock(planeMesh, font);
});

const pointer = new Vector2();
function onPointerMove(e) {
  pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
  pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
}

// Array of snap angles
const snapAngles = [0, 89, 140, 219, 270, 360];
var initialTouchY = null;
var isSnapping = false;
var snapTimeout = null;

function onWheel(event) {
  var delta = 0;
  if (event.type === 'wheel') {
    // Normalize wheel delta across different browsers
    delta = Math.sign(event.deltaY);
  } else if (event.type === 'touchstart') {
    // Store the initial touch position
    initialTouchY = event.touches[0].clientY;
  } else if (event.type === 'touchmove') {
    // Calculate the delta between the initial and current touch positions
    delta = (initialTouchY - event.touches[0].clientY) * 0.01;
  }

  // Animate the rotation of the group
  gsap.to(MgroupM.rotation, {
    y: MgroupM.rotation.y + delta * 1,
    duration: 1.5,
    onUpdate: () => {
      if (!isSnapping) {
        clearTimeout(snapTimeout);
        snapTimeout = setTimeout(snapToNearestAngle, 200); // Adjust the delay as needed
      }
    },
  });

  // Prevent default scroll behavior
  event.preventDefault();
}

// Function to snap to the nearest angle in the array
function snapToNearestAngle() {
  const currentRotation =
    (MgroupM.rotation.y * (180 / Math.PI)) % 360; // Get the current rotation within one full rotation
  const currentAngle =
    currentRotation >= 0 ? currentRotation : currentRotation + 360; // Convert negative angles to positive

  const nearestAngle = snapAngles.reduce((prevAngle, currAngle) => {
    const diff = Math.abs(currAngle - currentAngle);
    return diff < Math.abs(prevAngle - currentAngle)
      ? currAngle
      : prevAngle;
  });

  const targetRotation =
    (nearestAngle - currentAngle) * (Math.PI / 180);
  const duration = 0.8; // Animation duration in seconds
  const startTime = performance.now();
  const startRotation = MgroupM.rotation.y;
  const endRotation = startRotation + targetRotation;

  function animate(currentTime) {
    const elapsedTime = (currentTime - startTime) / 1000; // Convert to seconds
    const progress = Math.min(elapsedTime / duration, 1); // Clamp progress between 0 and 1
    const easedProgress = smoothStep(progress);
    const newRotation =
      startRotation + (endRotation - startRotation) * easedProgress;

    MgroupM.rotation.y = newRotation;

    if (progress < 1) {
      requestAnimationFrame(animate);
    } else {
      // Animation completed
      isSnapping = false;
    }
  }

  isSnapping = true;
  requestAnimationFrame(animate);
}

// Easing function for smooth step interpolation
function smoothStep(t) {
  return t * t * (3 - 2 * t);
}

// for mobile
const snapPositions = [0, -2, -6, -9, -13]; // Array of snap positions for camera movement
var initialTouchY2 = null;
var isSnapping2 = false;
var snapTimeout2 = null;

// Check if the device supports touch events
const isTouchDevice =
  'ontouchstart' in window || navigator.maxTouchPoints > 0;

// Attach event listeners only for touch devices
if (isTouchDevice) {
  document.addEventListener('touchstart', onTouchStart);
  document.addEventListener('touchmove', onTouchMove);
}

function onTouchStart(event) {
  initialTouchY2 = event.touches[0].clientY;
}

function onTouchMove(event) {
  var delta = (initialTouchY2 - event.touches[0].clientY) * 0.0008;

  // Animate the camera movement on the Y-axis
  gsap.to(camera.position, {
    y: camera.position.y - delta * 10, // Adjust the factor (10) to control the speed of movement
    duration: 1.5,
    onUpdate: () => {
      if (!isSnapping2) {
        clearTimeout(snapTimeout2);
        snapTimeout2 = setTimeout(snapToNearestPosition, 200); // Adjust the delay as needed
      }
    },
  });

  // Prevent default touchmove behavior
  event.preventDefault();
}

// Function to snap to the nearest position in the array
function snapToNearestPosition() {
  const currentPosition = camera.position.y;

  const nearestPosition = snapPositions.reduce(
    (prevPosition, currPosition) => {
      const diff = Math.abs(currPosition - currentPosition);
      return diff < Math.abs(prevPosition - currentPosition)
        ? currPosition
        : prevPosition;
    }
  );

  const targetPosition = nearestPosition;
  const duration = 0.8; // Animation duration in seconds
  const startTime = performance.now();
  const startPosition = camera.position.y;
  const endPosition = targetPosition;

  function animate(currentTime) {
    const elapsedTime = (currentTime - startTime) / 1000; // Convert to seconds
    const progress = Math.min(elapsedTime / duration, 1); // Clamp progress between 0 and 1
    const easedProgress = smoothStep2(progress);
    const newPosition =
      startPosition + (endPosition - startPosition) * easedProgress;

    camera.position.y = newPosition;

    if (progress < 1) {
      requestAnimationFrame(animate);
    } else {
      // Animation completed
      isSnapping2 = false;
    }
  }

  isSnapping2 = true;
  requestAnimationFrame(animate);
}

// Easing function for smooth step interpolation
function smoothStep2(t) {
  return t * t * (3 - 2 * t);
}

// // Add wheel event listener
// window.addEventListener('wheel', onWheel, { passive: false });
window.addEventListener('scroll', onWheel, { passive: false });
// window.addEventListener('touchmove', onWheel, { passive: false });

var hoveredObject = null;

// const stats = new Stats();
// document.body.appendChild(stats.dom);

// window.addEventListener('DOMContentLoaded', function () {
//   // Check if the animation has already been triggered in the current session
//   if (!sessionStorage.getItem('animationTriggered')) {
//     // Trigger the animation
//     startAnimation();

//     // Store a flag in sessionStorage to indicate that the animation has been triggered
//     sessionStorage.setItem('animationTriggered', true);
//   }
// });

// Create the GSAP animation timeline
const tlf = gsap.timeline({ repeat: -1, yoyo: false, paused: true });
function startAnimation() {
  tlf.play();
  // Define the rotation animation
  gsap.set('.mouse', { opacity: 1 });

  tlf
    .to(
      MgroupM.rotation,
      // { y: 0 },
      { y: 0.5, duration: 1.2, ease: 'power1.out' }
    )
    .to(
      MgroupM.rotation,
      // { y: 0.5 },
      { y: 0, duration: 1.2, ease: 'power1.in' }
    )
    .to(
      MgroupM.rotation,
      // { y: 0 },
      { y: -0.5, duration: 1.2, ease: 'power1.out' }
    )
    .to(
      MgroupM.rotation,
      // { y: -0.5 },
      { y: 0, duration: 1.2, ease: 'power1.in' }
    );
}
// Function to stop the animation
function stopAnimation() {
  tlf.pause();
  gsap.set('.mouse', { opacity: 0 });
}

// Add event listener to stop the animation on the first wheel or touch event
window.addEventListener('wheel', stopAnimation);
window.addEventListener('click', stopAnimation);
window.addEventListener('touchstart', stopAnimation);

// Function to update the watch hands' rotation based on current time

function updateWatchHands() {
  var time = new Date();

  var hours = time.getHours() + time.getMinutes() / 60;
  var minutes = time.getMinutes();
  var seconds = time.getSeconds();
  // var milliseconds = time.getMilliseconds();

  ghour.rotation.z = -((hours % 12) / 12) * Math.PI * 2;
  gmin.rotation.z = -(minutes / 60) * Math.PI * 2;
  gsec.rotation.z = -(seconds / 60) * Math.PI * 2;
  // -((seconds + milliseconds / 1000) / 60) * Math.PI * 2;
}

// Render to animation loop
function render() {
  if (mixer) {
    mixer.update(clock.getDelta());
  }
  updateWatchHands();
  if (resizeRendererToDisplaySize(renderer)) {
    const canvas = renderer.domElement;
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
  }
  updateClock(planeMesh, font);

  raycaster.setFromCamera(pointer, camera);
  const intersects = raycaster.intersectObjects(scene.children);

  if (intersects.length > 0) {
    // Get the first intersected object
    var object = intersects[0].object;

    // Check if the intersected object is different from the currently hovered object
    if (object !== hoveredObject) {
      // Remove hover effect from the previously hovered object
      if (hoveredObject) {
        // Remove hover effect
        removeHoverEffect(hoveredObject);
      }

      // Set the currently hovered object
      hoveredObject = object;

      // Apply hover effect to the currently hovered object
      applyHoverEffect(hoveredObject);
    }
  } else {
    // No objects are intersected, remove hover effect from the previously hovered object
    if (hoveredObject) {
      // Remove hover effect
      removeHoverEffect(hoveredObject);
      hoveredObject = null;
    }
  }

  Mgroup2.lookAt(camera.position);
  Mgroup3.lookAt(camera.position);
  Mgroup4.lookAt(camera.position);
  Mgroup5.lookAt(camera.position);
  // stats.update();

  renderer.render(scene, camera);
  requestAnimationFrame(render);
}
window.addEventListener('pointermove', onPointerMove);
render();

function resizeRendererToDisplaySize(renderer) {
  const canvas = renderer.domElement;
  const width = window.innerWidth;
  const height = window.innerHeight;
  const canvasPixelWidth = canvas.width / window.devicePixelRatio;
  const canvasPixelHeight = canvas.height / window.devicePixelRatio;
  const needResize =
    canvasPixelWidth !== width || canvasPixelHeight !== height;
  if (needResize) {
    renderer.setSize(width, height, false);
  }
  return needResize;
}

// Function to apply hover effect to an object
function applyHoverEffect(object) {
  // Apply hover effect based on object type
  if (
    object.name === 'watch_1' ||
    object.name === 'watch_2' ||
    object.name === 'watch_3'
  ) {
    // Apply hover effect for object1
    body.style.cursor = 'none';
    if (!currentlyAnimating) {
      textVar = 'Smart Watch Apps';
    } else {
      if (
        possibleAnims[anim]._clip.name === 'hiphop' ||
        possibleAnims[anim]._clip.name === 'hiphop3' ||
        possibleAnims[anim]._clip.name === 'shuffling'
      ) {
        textVar = "HE'S DANCING WAIT";
      } else if (possibleAnims[anim]._clip.name === 'spin') {
        textVar = "HE'S SPINNING WAIT";
      } else if (possibleAnims[anim]._clip.name === 'salute') {
        textVar = 'HE SALUTE WAIT';
      } else if (possibleAnims[anim]._clip.name === 'look') {
        textVar = "HE'S LOOKING WAIT";
      }
    }
    cursor.setText(textVar);
    cursor.addState('-inverse');
    cursor.removeState('-exclusion');
    gsap.to(Mgroup2.scale, {
      duration: 0.4,
      x: 1.3,
      y: 1.3,
      z: 1.3,
      ease: 'power1',
    });
  } else if (object.name === 'clickmesh') {
    // Apply hover effect for object2
    if (!currentlyAnimating) {
      body.style.cursor = 'none';
      textVar = 'Click On My Avatar';
      cursor.setText(textVar);
      cursor.addState('-inverse');
      cursor.removeState('-exclusion');
    } else {
      body.style.cursor = 'none';
      if (
        possibleAnims[anim]._clip.name === 'hiphop' ||
        possibleAnims[anim]._clip.name === 'hiphop3' ||
        possibleAnims[anim]._clip.name === 'shuffling'
      ) {
        textVar = "HE'S DANCING WAIT";
      } else if (possibleAnims[anim]._clip.name === 'spin') {
        textVar = "HE'S SPINNING WAIT";
      } else if (possibleAnims[anim]._clip.name === 'salute') {
        textVar = 'HE SALUTE WAIT';
      } else if (possibleAnims[anim]._clip.name === 'look') {
        textVar = "HE'S LOOKING WAIT";
      }
      // textVar = "HE'S MOVING WAIT";
      cursor.setText(textVar);
      cursor.addState('-inverse');
      cursor.removeState('-exclusion');
    }
  } else if (
    object.name === 'front_1' ||
    object.name === 'front_2' ||
    object.name === 'front_3'
  ) {
    // Apply hover effect for object3
    body.style.cursor = 'none';
    if (!currentlyAnimating) {
      textVar = 'AR Virtual Try-On';
    } else {
      if (
        possibleAnims[anim]._clip.name === 'hiphop' ||
        possibleAnims[anim]._clip.name === 'hiphop3' ||
        possibleAnims[anim]._clip.name === 'shuffling'
      ) {
        textVar = "HE'S DANCING WAIT";
      } else if (possibleAnims[anim]._clip.name === 'spin') {
        textVar = "HE'S SPINNING WAIT";
      } else if (possibleAnims[anim]._clip.name === 'salute') {
        textVar = 'HE SALUTE WAIT';
      } else if (possibleAnims[anim]._clip.name === 'look') {
        textVar = "HE'S LOOKING WAIT";
      }
    }
    // textVar = 'AR Virtual Try-On';
    cursor.addState('-inverse');
    cursor.removeState('-exclusion');
    cursor.setText(textVar);
    gsap.to(Mgroup3.scale, {
      duration: 0.4,
      x: 1.3,
      y: 1.3,
      z: 1.3,
      ease: 'power1',
    });
  } else if (object.name === 'comp_1' || object.name === 'comp_2') {
    // Apply hover effect for object3
    body.style.cursor = 'none';
    if (!currentlyAnimating) {
      textVar = 'Music Learning App';
    } else {
      if (
        possibleAnims[anim]._clip.name === 'hiphop' ||
        possibleAnims[anim]._clip.name === 'hiphop3' ||
        possibleAnims[anim]._clip.name === 'shuffling'
      ) {
        textVar = "HE'S DANCING WAIT";
      } else if (possibleAnims[anim]._clip.name === 'spin') {
        textVar = "HE'S SPINNING WAIT";
      } else if (possibleAnims[anim]._clip.name === 'salute') {
        textVar = 'HE SALUTE WAIT';
      } else if (possibleAnims[anim]._clip.name === 'look') {
        textVar = "HE'S LOOKING WAIT";
      }
    }
    // textVar = 'Music Learning App';
    cursor.setText(textVar);
    cursor.addState('-inverse');
    cursor.removeState('-exclusion');
    gsap.to(Mgroup4.scale, {
      duration: 0.4,
      x: 1.3,
      y: 1.3,
      z: 1.3,
      ease: 'power1',
    });
  } else if (
    object.name === 'apple_1' ||
    object.name === 'apple_2' ||
    object.name === 'apple_3' ||
    object.name === 'apple_4' ||
    object.name === 'apple_5' ||
    object.name === 'apple_6' ||
    object.name === 'apple_7' ||
    object.name === 'apple_8' ||
    object.name === 'apple_9' ||
    object.name === 'apple_10' ||
    object.name === 'apple_11' ||
    object.name === 'apple_12' ||
    object.name === 'apple_13' ||
    object.name === 'apple_14'
  ) {
    // Apply hover effect for object3
    body.style.cursor = 'none';
    if (!currentlyAnimating) {
      textVar = 'VisionOS Spacial App';
    } else {
      if (
        possibleAnims[anim]._clip.name === 'hiphop' ||
        possibleAnims[anim]._clip.name === 'hiphop3' ||
        possibleAnims[anim]._clip.name === 'shuffling'
      ) {
        textVar = "HE'S DANCING WAIT";
      } else if (possibleAnims[anim]._clip.name === 'spin') {
        textVar = "HE'S SPINNING WAIT";
      } else if (possibleAnims[anim]._clip.name === 'salute') {
        textVar = 'HE SALUTE WAIT';
      } else if (possibleAnims[anim]._clip.name === 'look') {
        textVar = "HE'S LOOKING WAIT";
      }
    }
    // textVar = 'VisionOS Spacial App';
    cursor.setText(textVar);
    cursor.addState('-inverse');
    cursor.removeState('-exclusion');
    gsap.to(Mgroup5.scale, {
      duration: 0.4,
      x: 1.3,
      y: 1.3,
      z: 1.3,
      ease: 'power1',
    });
  }
}

// Function to removehover effect from an object
function removeHoverEffect(object) {
  // Remove hover effect based on object type
  if (
    object.name === 'watch_1' ||
    object.name === 'watch_2' ||
    object.name === 'watch_3'
  ) {
    // Remove hover effect for object1
    body.style.cursor = 'auto';
    cursor.removeText();
    cursor.removeState('-inverse');
    cursor.addState('-exclusion');
    gsap.to(Mgroup2.scale, {
      duration: 0.4,
      x: 1,
      y: 1,
      z: 1,
      ease: 'power1',
    });
  } else if (object.name === 'clickmesh') {
    // Remove hover effect for object2
    body.style.cursor = 'auto';
    cursor.removeText();
    cursor.removeState('-inverse');
    cursor.addState('-exclusion');
  } else if (
    object.name === 'front_1' ||
    object.name === 'front_2' ||
    object.name === 'front_3'
  ) {
    // Remove hover effect for object3
    body.style.cursor = 'auto';
    cursor.removeText();
    cursor.removeState('-inverse');
    cursor.addState('-exclusion');
    gsap.to(Mgroup3.scale, {
      duration: 0.4,
      x: 1,
      y: 1,
      z: 1,
      ease: 'power1',
    });
  } else if (object.name === 'comp_1' || object.name === 'comp_2') {
    // Remove hover effect for object3
    body.style.cursor = 'auto';
    cursor.removeText();
    cursor.removeState('-inverse');
    cursor.addState('-exclusion');
    gsap.to(Mgroup4.scale, {
      duration: 0.4,
      x: 1,
      y: 1,
      z: 1,
      ease: 'power1',
    });
  } else if (
    object.name === 'apple_1' ||
    object.name === 'apple_2' ||
    object.name === 'apple_3' ||
    object.name === 'apple_4' ||
    object.name === 'apple_5' ||
    object.name === 'apple_6' ||
    object.name === 'apple_7' ||
    object.name === 'apple_8' ||
    object.name === 'apple_9' ||
    object.name === 'apple_10' ||
    object.name === 'apple_11' ||
    object.name === 'apple_12' ||
    object.name === 'apple_13' ||
    object.name === 'apple_14'
  ) {
    // Apply hover effect for object3
    body.style.cursor = 'auto';
    cursor.removeText();
    cursor.removeState('-inverse');
    cursor.addState('-exclusion');
    gsap.to(Mgroup5.scale, {
      duration: 0.4,
      x: 1,
      y: 1,
      z: 1,
      ease: 'power1',
    });
  }
}

function raycast(e, touch = false) {
  const mouse = {};
  if (touch) {
    mouse.x =
      2 * (e.changedTouches[0].clientX / window.innerWidth) - 1;
    mouse.y =
      1 - 2 * (e.changedTouches[0].clientY / window.innerHeight);
  } else {
    mouse.x = 2 * (e.clientX / window.innerWidth) - 1;
    mouse.y = 1 - 2 * (e.clientY / window.innerHeight);
  }
  // update the picking ray with the camera and mouse position
  raycaster.setFromCamera(mouse, camera);

  // calculate objects intersecting the picking ray
  const intersects = raycaster.intersectObjects(scene.children, true);

  if (intersects[0]) {
    const object = intersects[0].object;
    if (!currentlyAnimating) {
      if (
        object.name === 'watch_1' ||
        object.name === 'watch_2' ||
        object.name === 'watch_3'
      ) {
        let tli = gsap.timeline({});

        tli
          .fromTo(
            Mgroup4.position,
            { x: 1 },
            {
              x: 10,
              duration: 1,
              ease: 'power1.inOut',
              onComplete: () => {
                sound.stop();
                watch.stop();
              },
            }
          )
          .fromTo(
            Mgroup5.position,
            { x: -1 },
            {
              x: -10,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            Mgroup2.position,
            { x: -1.6 },
            {
              x: -10,
              duration: 1,
              ease: 'power1.inOut',
              onStart: () => {
                sound2.offset = 0.1;
                sound2.play();
              },
            },
            '-=0.9'
          )
          .fromTo(
            Mgroup3.position,
            { x: 1.6 },
            {
              x: 10,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )

          .fromTo(
            Mgroup.position,
            { z: 0 },
            {
              z: 4,
              duration: 2,
              ease: 'power1',
              onStart: () => {
                playModifierAnimation(idle, 0.25, spinning, 0.25);

                sound4.play();
                // dialogwatch.close();
              },
              onComplete: () => {
                dialogwatch.showModal();
              },
            }
          )
          .fromTo(
            '.contact',
            { y: 0 },
            {
              y: 50,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=2'
          )

          .to(
            '.name, .name3',
            { duration: 1, opacity: 0, ease: 'power1' },
            '-=1'
          )
          .to('body, html', {
            duration: 1,
            opacity: 0,
          });
      } else if (object.name === 'clickmesh') {
        if (!currentlyAnimating) {
          currentlyAnimating = true;
          playOnClick();
        }
      } else if (
        object.name === 'front_1' ||
        object.name === 'front_2' ||
        object.name === 'front_3'
      ) {
        let tli = gsap.timeline({});

        tli
          .fromTo(
            Mgroup4.position,
            { x: 1 },
            {
              x: 10,
              duration: 1,
              ease: 'power1.inOut',
              onComplete: () => {
                sound.stop();
                watch.stop();
              },
            }
          )
          .fromTo(
            Mgroup5.position,
            { x: -1 },
            {
              x: -10,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            Mgroup2.position,
            { x: -1.6 },
            {
              x: -10,
              duration: 1,
              ease: 'power1.inOut',
              onStart: () => {
                sound2.offset = 0.1;
                sound2.play();
              },
            },
            '-=0.9'
          )
          .fromTo(
            Mgroup3.position,
            { x: 1.6 },
            {
              x: 10,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )

          .fromTo(
            Mgroup.position,
            { z: 0 },
            {
              z: 4,
              duration: 2,
              ease: 'power1',
              onStart: () => {
                playModifierAnimation(idle, 0.25, spinning, 0.25);

                sound4.play();
                // dialogwatch.close();
              },
              onComplete: () => {
                dialogAr.showModal();
              },
            }
          )
          .fromTo(
            '.contact',
            { y: 0 },
            {
              y: 50,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=2'
          )

          .to(
            '.name, .name3',
            { duration: 1, opacity: 0, ease: 'power1' },
            '-=1'
          )
          .to('body, html', {
            duration: 1,
            opacity: 0,
          });
      } else if (
        object.name === 'comp_1' ||
        object.name === 'comp_2'
      ) {
        let tli = gsap.timeline({});

        tli
          .fromTo(
            Mgroup4.position,
            { x: 1 },
            {
              x: 10,
              duration: 1,
              ease: 'power1.inOut',
              onComplete: () => {
                sound.stop();
                watch.stop();
              },
            }
          )
          .fromTo(
            Mgroup5.position,
            { x: -1 },
            {
              x: -10,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            Mgroup2.position,
            { x: -1.6 },
            {
              x: -10,
              duration: 1,
              ease: 'power1.inOut',
              onStart: () => {
                sound2.offset = 0.1;
                sound2.play();
              },
            },
            '-=0.9'
          )
          .fromTo(
            Mgroup3.position,
            { x: 1.6 },
            {
              x: 10,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )

          .fromTo(
            Mgroup.position,
            { z: 0 },
            {
              z: 4,
              duration: 2,
              ease: 'power1',
              onStart: () => {
                playModifierAnimation(idle, 0.25, spinning, 0.25);

                sound4.play();
                // dialogwatch.close();
              },
              onComplete: () => {
                dialogmusic.showModal();
              },
            }
          )
          .fromTo(
            '.contact',
            { y: 0 },
            {
              y: 50,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=2'
          )

          .to(
            '.name, .name3',
            { duration: 1, opacity: 0, ease: 'power1' },
            '-=1'
          )
          .to('body, html', {
            duration: 1,
            opacity: 0,
          });
      } else if (
        object.name === 'apple_1' ||
        object.name === 'apple_2' ||
        object.name === 'apple_3' ||
        object.name === 'apple_4' ||
        object.name === 'apple_5' ||
        object.name === 'apple_6' ||
        object.name === 'apple_7' ||
        object.name === 'apple_8' ||
        object.name === 'apple_9' ||
        object.name === 'apple_10' ||
        object.name === 'apple_11' ||
        object.name === 'apple_12' ||
        object.name === 'apple_13' ||
        object.name === 'apple_14'
      ) {
        let tli = gsap.timeline({});

        tli
          .fromTo(
            Mgroup4.position,
            { x: 1 },
            {
              x: 10,
              duration: 1,
              ease: 'power1.inOut',
              onComplete: () => {
                sound.stop();
                watch.stop();
              },
            }
          )
          .fromTo(
            Mgroup5.position,
            { x: -1 },
            {
              x: -10,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            Mgroup2.position,
            { x: -1.6 },
            {
              x: -10,
              duration: 1,
              ease: 'power1.inOut',
              onStart: () => {
                sound2.offset = 0.1;
                sound2.play();
              },
            },
            '-=0.9'
          )
          .fromTo(
            Mgroup3.position,
            { x: 1.6 },
            {
              x: 10,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )

          .fromTo(
            Mgroup.position,
            { z: 0 },
            {
              z: 4,
              duration: 2,
              ease: 'power1',
              onStart: () => {
                playModifierAnimation(idle, 0.25, spinning, 0.25);

                sound4.play();
                // dialogwatch.close();
              },
              onComplete: () => {
                dialogapple.showModal();
              },
            }
          )
          .fromTo(
            '.contact',
            { y: 0 },
            {
              y: 50,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=2'
          )

          .to(
            '.name, .name3',
            { duration: 1, opacity: 0, ease: 'power1' },
            '-=1'
          )
          .to('body, html', {
            duration: 1,
            opacity: 0,
          });
      }
    }
  }
}

const el = document.querySelectorAll('a');

el.forEach((element) => {
  element.addEventListener('mouseenter', () => {
    cursor.removeState('-exclusion'); // you can pass multiple states separated by whitespace
  });

  element.addEventListener('mouseleave', () => {
    cursor.addState('-exclusion');
  });
});

window.addEventListener('click', (e) => raycast(e));
window.addEventListener('touchstart', (e) => raycast(e, true));

// Get a random animation, play it, and drive the progress bar
function playOnClick() {
  anim = Math.floor(Math.random() * possibleAnims.length) + 0;
  playModifierAnimation(idle, 0.25, possibleAnims[anim], 0.25);
  const time = possibleAnims[anim]._clip.duration - 0.5;
  // console.log(possibleAnims[anim]._clip.name);
  // console.log(possibleAnims[anim]._clip.duration);
  if (
    possibleAnims[anim]._clip.name === 'hiphop' ||
    possibleAnims[anim]._clip.name === 'hiphop3' ||
    possibleAnims[anim]._clip.name === 'shuffling'
  ) {
    sound3.play();
    textVar = "HE'S DANCING WAIT";
  } else if (possibleAnims[anim]._clip.name === 'spin') {
    sound4.play();
    textVar = "HE'S SPINNING WAIT";
  } else if (possibleAnims[anim]._clip.name === 'salute') {
    salute.play();
    textVar = 'HE SALUTE WAIT';
  } else if (possibleAnims[anim]._clip.name === 'look') {
    looking.play();
    textVar = "HE'S LOOKING WAIT";
  }
  cursor.setText(textVar);
  document.body.style.setProperty('--text', `"${textVar}"`);
  setTimeout(() => {
    sound3.stop();
    sound4.stop();
    salute.stop();
    looking.stop();
    if (matchMedia('(pointer:fine)').matches) {
      textVar = 'Click On My Avatar';
      cursor.setText(textVar);
      cursor.removeText();
      document.body.style.setProperty('--text', `"${textVar}"`);
    } else {
      textVar = 'TOUCH AGAIN';
      cursor.setText(textVar);
      document.body.style.setProperty('--text', `"${textVar}"`);
    }
  }, time * 1000);
}

function playModifierAnimation(from, fSpeed, to, tSpeed) {
  to.setLoop(LoopOnce);
  to.reset();
  to.play();
  from.crossFadeTo(to, fSpeed, true);
  setTimeout(function () {
    from.enabled = true;
    to.crossFadeTo(from, tSpeed, true);
    currentlyAnimating = false;
  }, to._clip.duration * 1000 - (tSpeed + fSpeed) * 1000);
}

window.addEventListener('message', (event) => {
  let tlo = gsap.timeline({});

  if (MessageEvent.origin !== 'https://frankdufaux.com') {
    console.log(`Received message: ${event.data}`);
    switch (event.data) {
      case 'closewatch':
        playModifierAnimation(idle, 0.25, spinning, 0.25);
        tlo
          .set('body, html', {
            opacity: 1,
          })
          .fromTo(
            Mgroup.position,
            { z: 4 },
            {
              z: 0,
              duration: 2,
              ease: 'power1',
              onStart: () => {
                sound4.play();
                dialogwatch.close();
              },
            }
          )
          .fromTo(
            Mgroup4.position,
            { x: 10 },
            {
              x: 1,
              duration: 1,
              ease: 'power1.inOut',
              onComplete: () => {
                sound.play();
                watch.play();
              },
            },
            '-=1'
          )
          .fromTo(
            Mgroup5.position,
            { x: -10 },
            {
              x: -1,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            Mgroup2.position,
            { x: -10 },
            {
              x: -1.6,
              duration: 1,
              ease: 'power1.inOut',
              onStart: () => {
                sound2.offset = 0.1;
                sound2.play();
              },
            },
            '-=0.9'
          )
          .fromTo(
            Mgroup3.position,
            { x: 10 },
            {
              x: 1.6,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            '.contact',
            { y: 50 },
            {
              y: 0,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )

          .to(
            '.name, .name3',
            { duration: 1, opacity: 1, ease: 'power1' },
            '-=1'
          );

        break;
      case 'closear':
        playModifierAnimation(idle, 0.25, spinning, 0.25);
        tlo
          .set('body, html', {
            opacity: 1,
          })
          .fromTo(
            Mgroup.position,
            { z: 4 },
            {
              z: 0,
              duration: 2,
              ease: 'power1',
              onStart: () => {
                sound4.play();
                dialogAr.close();
              },
            }
          )
          .fromTo(
            Mgroup4.position,
            { x: 10 },
            {
              x: 1,
              duration: 1,
              ease: 'power1.inOut',
              onComplete: () => {
                sound.play();
                watch.play();
              },
            },
            '-=1'
          )
          .fromTo(
            Mgroup5.position,
            { x: -10 },
            {
              x: -1,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            Mgroup2.position,
            { x: -10 },
            {
              x: -1.6,
              duration: 1,
              ease: 'power1.inOut',
              onStart: () => {
                sound2.offset = 0.1;
                sound2.play();
              },
            },
            '-=0.9'
          )
          .fromTo(
            Mgroup3.position,
            { x: 10 },
            {
              x: 1.6,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            '.contact',
            { y: 50 },
            {
              y: 0,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )

          .to(
            '.name, .name3',
            { duration: 1, opacity: 1, ease: 'power1' },
            '-=1'
          );
        break;
      case 'closemusic':
        playModifierAnimation(idle, 0.25, spinning, 0.25);
        tlo
          .set('body, html', {
            opacity: 1,
          })
          .fromTo(
            Mgroup.position,
            { z: 4 },
            {
              z: 0,
              duration: 2,
              ease: 'power1',
              onStart: () => {
                sound4.play();
                dialogmusic.close();
              },
            }
          )
          .fromTo(
            Mgroup4.position,
            { x: 10 },
            {
              x: 1,
              duration: 1,
              ease: 'power1.inOut',
              onComplete: () => {
                sound.play();
                watch.play();
              },
            },
            '-=1'
          )
          .fromTo(
            Mgroup5.position,
            { x: -10 },
            {
              x: -1,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            Mgroup2.position,
            { x: -10 },
            {
              x: -1.6,
              duration: 1,
              ease: 'power1.inOut',
              onStart: () => {
                sound2.offset = 0.1;
                sound2.play();
              },
            },
            '-=0.9'
          )
          .fromTo(
            Mgroup3.position,
            { x: 10 },
            {
              x: 1.6,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            '.contact',
            { y: 50 },
            {
              y: 0,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )

          .to(
            '.name, .name3',
            { duration: 1, opacity: 1, ease: 'power1' },
            '-=1'
          );
        break;
      case 'closeapple':
        playModifierAnimation(idle, 0.25, spinning, 0.25);
        tlo
          .set('body, html', {
            opacity: 1,
          })
          .fromTo(
            Mgroup.position,
            { z: 4 },
            {
              z: 0,
              duration: 2,
              ease: 'power1',
              onStart: () => {
                sound4.play();
                dialogapple.close();
              },
            }
          )
          .fromTo(
            Mgroup4.position,
            { x: 10 },
            {
              x: 1,
              duration: 1,
              ease: 'power1.inOut',
              onComplete: () => {
                sound.play();
                watch.play();
              },
            },
            '-=1'
          )
          .fromTo(
            Mgroup5.position,
            { x: -10 },
            {
              x: -1,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            Mgroup2.position,
            { x: -10 },
            {
              x: -1.6,
              duration: 1,
              ease: 'power1.inOut',
              onStart: () => {
                sound2.offset = 0.1;
                sound2.play();
              },
            },
            '-=0.9'
          )
          .fromTo(
            Mgroup3.position,
            { x: 10 },
            {
              x: 1.6,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )
          .fromTo(
            '.contact',
            { y: 50 },
            {
              y: 0,
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=1'
          )

          .to(
            '.name, .name3',
            { duration: 1, opacity: 1, ease: 'power1' },
            '-=1'
          );

        break;
      default:
      // code block
    }
  }
});

function look(e) {
  const mousecoords = getMousePos(e);
  if (neck && waist) {
    moveJoint(mousecoords, neck, 50);
    moveJoint(mousecoords, waist, 30);
  }
}

document.addEventListener('mousemove', look);

function getMousePos(e) {
  return { x: e.clientX, y: e.clientY };
}

function moveJoint(mouse, joint, degreeLimit) {
  const degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit);
  joint.rotation.y = MathUtils.degToRad(degrees.x);
  joint.rotation.x = MathUtils.degToRad(degrees.y);
}

function getMouseDegrees(x, y, degreeLimit) {
  let dx = 0;
  let dy = 0;
  let xdiff;
  let xPercentage;
  let ydiff;
  let yPercentage;

  const w = { x: window.innerWidth, y: window.innerHeight * 0.4 };

  // Left (Rotates neck left between 0 and -degreeLimit)
  // 1. If cursor is in the left half of screen
  if (x <= w.x / 2) {
    // 2. Get the difference between middle of screen and cursor position
    xdiff = w.x / 2 - x;
    // 3. Find the percentage of that difference (percentage toward edge of screen)
    xPercentage = (xdiff / (w.x / 2)) * 100;
    // 4. Convert that to a percentage of the maximum rotation we allow for the neck
    dx = ((degreeLimit * xPercentage) / 100) * -1;
  }

  // Right (Rotates neck right between 0 and degreeLimit)
  if (x >= w.x / 2) {
    xdiff = x - w.x / 2;
    xPercentage = (xdiff / (w.x / 2)) * 100;
    dx = (degreeLimit * xPercentage) / 100;
  }
  // Up (Rotates neck up between 0 and -degreeLimit)
  if (y <= w.y / 2) {
    ydiff = w.y / 2 - y;
    yPercentage = (ydiff / (w.y / 2)) * 100;
    // Note that I cut degreeLimit in half when she looks up
    dy = ((degreeLimit * 0.5 * yPercentage) / 100) * -1;
  }
  // Down (Rotates neck down between 0 and degreeLimit)
  if (y >= w.y / 2) {
    ydiff = y - w.y / 2;
    yPercentage = (ydiff / (w.y / 2)) * 100;
    dy = ((degreeLimit / 5.5) * yPercentage) / 100;
  }
  return { x: dx, y: dy };
}
